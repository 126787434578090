export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";

export function truncateCaption(
  caption: string,
  maxLength = 300
): { truncatedCaption: string; isTruncated: boolean } {
  if (caption.length > maxLength) {
    return {
      truncatedCaption: caption.substring(0, maxLength) + "...",
      isTruncated: true,
    };
  }
  return { truncatedCaption: caption, isTruncated: false };
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function truncateDate(dateString: string): string {
  return dateString.split("T")[0];
}
