import React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Grid,
  extendTheme,
} from "@chakra-ui/react";
import { EmbedBox } from "./EmbedBox";
import "./fonts.css";

const theme = extendTheme({
  styles: {
    global: {
      // Apply styles globally across all elements
      "html, body": {
        backgroundColor: "#26262e", // Example color
        color: "#fffbf2", // Adjust text color as needed
      },
    },
    fonts: {
      heading: "Integral",
      letterSpacing: "-2%",
    },
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign='center' fontSize='xl'>
      <Grid minH='100vh' p={{ base: 1, md: 4, lg: 4 }}>
        <VStack spacing={0}>
          <Text
            fontSize='6xl'
            fontFamily={"Integral, sans-serif"}
            fontWeight={600}
          >
            PRESS IN GAZA
          </Text>
          <Text
            fontSize='large'
            fontFamily={"Lucida Console, monospace"}
            fontWeight={"530"}
            color={"#439c59"}
            paddingBottom={17}
          >
            social media aggregator
          </Text>{" "}
          {/* Padding top between header and grid */}
          <EmbedBox />
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
);
