import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { Post } from "./types";
import { truncateCaption, truncateDate } from "./lib/util";

export const PostBox = ({ post }: { post: Post }) => {
  return (
    <Box
      key={post.url}
      maxW='md'
      borderWidth='0px'
      borderRadius='lg'
      width='383px'
      backgroundColor={"white"}
    >
      <Box overflow='hidden' borderTopRadius='lg' height='520px' width='383px'>
        <iframe
          src={`${post.url}embed`}
          width='100%'
          height='620px'
          style={{ display: "block" }}
          scrolling='no'
        />
      </Box>

      <Box
        mt='1'
        fontFamily={"lucida console, monospace"}
        fontWeight={"2"}
        fontSize={"md"}
        padding={4}
        textAlign={"left"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        style={{ height: "calc(100% - 520px)" }}
      >
        <Text as='p' color={"black"} lineHeight='tight'>
          <b>
            <a style={{ color: "#439c59" }}>{post.user_handle}</a>
          </b>
          : {post.caption}
        </Text>
        <Text
          padding='5px'
          as='p'
          color={"grey"}
          lineHeight='tight'
          opacity={"1"}
          align={"right"}
          position={"relative"}
        >
          {truncateDate(post.post_date)}
        </Text>
      </Box>
    </Box>
  );
};
