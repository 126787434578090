import React, { useEffect, useState, useCallback } from "react";
import { SimpleGrid, Box, Text, Button, Collapse } from "@chakra-ui/react";
import { Post } from "./types";
import { apiUrl, formatDate } from "./lib/util";
import { PostBox } from "./PostBox";

export const EmbedBox = () => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [dateSortedPosts, setDateSortedPosts] = useState<
    Record<string, Post[]>
  >({});

  const loadPosts = useCallback(() => {
    fetch(`${apiUrl}/api/posts/embed?page=${page}&limit=10`)
      .then((res) => res.json())
      .then((data) => {
        if (data.posts.length < 10) setHasMore(false); // Assuming 10 is the limit per page

        // Add any new posts to the date sorted list
        const keysAltered = [];
        const temp = { ...dateSortedPosts };
        for (const post of data.posts) {
          console.log(post.post_date);
          const formattedDate = new Date(post.post_date);
          const date = formattedDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          if (!temp[date]) {
            temp[date] = [post];
            keysAltered.push(date);
          } else if (!temp[date].some((p) => p.url === post.url)) {
            temp[date].push(post);
            keysAltered.push(date);
          }
        }

        // Now sort every date's posts by their date (newest first)
        for (const date in temp) {
          if (!keysAltered.includes(date)) continue; // Only sort the dates that have been altered
          temp[date].sort(
            (a, b) =>
              new Date(b.post_date).getTime() - new Date(a.post_date).getTime()
          );
        }

        // Set the new date sorted posts
        setDateSortedPosts(temp);
      })
      .catch((err) => console.log(err));
  }, [page]);

  const handleScroll = useCallback(() => {
    const bottomReached =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 100; // Check if near the bottom

    if (!bottomReached || !hasMore) {
      return;
    }

    console.log("Loading more posts...");
    setPage((prevPage) => prevPage + 1);
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  const sortedDateKeys: string[] = Object.keys(dateSortedPosts).sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime()
  );

  return (
    <PostsGrid
      sortedDateKeys={sortedDateKeys}
      dateSortedPosts={dateSortedPosts}
    />
  );
};

const PostsGrid = ({
  sortedDateKeys,
  dateSortedPosts,
}: {
  sortedDateKeys: string[];
  dateSortedPosts: Record<string, Post[]>;
}) => {
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});

  // Set all sections to be open initially
  useEffect(() => {
    const initialOpenSections = sortedDateKeys.reduce((acc, date) => {
      acc[date] = true; // Set each section to be open
      return acc;
    }, {} as Record<string, boolean>);
    setOpenSections(initialOpenSections);
  }, [sortedDateKeys]);

  return (
    <Box>
      {sortedDateKeys.map((date) => (
        <Box key={date} mb='5' pb={8}>
          <Button
            colorScheme='red'
            variant='ghost'
            w='full'
            textAlign='left'
            justifyContent='flex-start' // Aligns content to the left
            pl={2} // Padding left for the button text
            marginBottom={8}
          >
            <Text
              fontSize='3xl'
              fontFamily={"Gerogia, serif"}
              fontStyle={"italic"}
              color={"#e6474c"}
              fontWeight={"530"}
              textTransform={"uppercase"}
            >
              {formatDate(date)}
            </Text>
          </Button>
          <Collapse in={openSections[date] || false} animateOpacity>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={{ base: 7, md: 30, lg: 30 }}
              minWidth='383px'
            >
              {dateSortedPosts[date].map((post) => (
                <PostBox key={post.url} post={post} />
              ))}
            </SimpleGrid>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};
